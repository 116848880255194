import Get from "../methods/Get";
import Post from "../methods/Post";

const getTemplateFollowUpParams = (token) => Get(`lp/template-follow-up/param`, { "Authorization": `Bearer ${token}` });
const getTemplateFollowUp = (token) => Get(`lp/template-follow-up`, { "Authorization": `Bearer ${token}` });
const postTemplateFollowUp = (token, data) => Post(`lp/template-follow-up`, data, token);

const getTemplateFollowUpById = (token, id) => Get(`lp/orders/${id}/get-message-follow-up`, { "Authorization": `Bearer ${token}` });

const postTemplateFollowUpById = (token, id, data) => Post(`lp/orders/${id}/send-follow-up`, data, token);

const postFollowUp = (token, id) => Post(`lp/orders/${id}/follow-up`, {}, token);

export const templateFollowUp = {
  getTemplateFollowUpParams,
  getTemplateFollowUp,
  postTemplateFollowUp,
  getTemplateFollowUpById,
  postTemplateFollowUpById,
  postFollowUp,
};