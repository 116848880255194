import Post from '../methods/Post';

// ================== Logout ================== 

const logoutAll = data => Post(`logout-all`, data);

const logoutAllSendEmail = data => Post(`send-email-logout-all`, data);

export const logout = {
    logoutAll,
    logoutAllSendEmail
}
