import Get from '../methods/Get';

// ================== SEARCH ==================
// GetSearch
const getSearch = keyword => Get(`search?keyword=${keyword}&limit=50`, null);

// Get search member address
const searchAddress = keyword => Get(`addressprovider?name=${keyword}`, null);

export const search = {
    getSearch,
    searchAddress
}