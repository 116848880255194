import Get from '../methods/Get';
import Post from '../methods/Post';

// ================== COMPLAINT ==================
// Upload image complaint
const postFileComplaint = (token, data) => Post('complaint/upload-file', data, token);

// Post Form Complaint
const postComplaint = (data, token) => Post('complaint', data, token);

// Post Form Complaint2
const postComplaint2 = (data, token) => Post('complaint2', data, token);

// Post Form Complaint
const postCompensite = (data, token) => Post('compensation', data, token);

// Get Complaint
const getComplaint = (orderId, conversation_id, token) => Get(`complaint?order_id=${orderId}&conversation_id=${conversation_id}`, { "Authorization": `Bearer ${token}` });

// Get Complaint
const getCompensation = (orderId, conversation_id, token) => Get(`compensation?order_id=${orderId}&conversation_id=${conversation_id}`, { "Authorization": `Bearer ${token}` });

export const complaint = {
    postFileComplaint,
    postComplaint,
    getComplaint,
    getCompensation,
    postCompensite,
    postComplaint2,
};