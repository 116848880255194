/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useContext, useState } from 'react'
import ActionType from '../redux/reducer/globalActionType'
import { connect } from 'react-redux'
import API from '../services';
import { useEffect } from 'react';

const appVersionName = '_app_version';

const isNeedUpdate = (data) => {
  const { updated } = data;
  if (typeof updated === 'undefined') {
    return true;
  }

  if (data?.lastPaymentTimestamp) return true;

  if (Date.now() - updated > 3600 * 1000) {
    return true;
  }

  return false;
}


const AccountContext = createContext(null)

const AccountProviderComponent = ({ children, ...props }) => {
  const {
    isLogin,
    idMember,
    token,
    reduxAccount,
    setAccount,
    logout,
    fbCheck,
    setFacebookCheck
  } = props

  const [isLoading, setIsLoading] = useState(true)
  const [isExists, setIsExists] = useState()

  const getAccount = async () => {
    let result;
    result = await API.getDetailMember(idMember, token)

    const appVersionLocal = sessionStorage.getItem(appVersionName);
    const appVersion = result.data.appVersion;

    if (!appVersionLocal) {
      sessionStorage.setItem(appVersionName, appVersion);
    }

    if (!!appVersionLocal && appVersionLocal !== appVersion) {
      sessionStorage.removeItem(appVersionName);
      window.location.reload(true);
      return;
    }

    if (result.status === 200 && result.data.success) {
      setAccount({
        data: { ...result.data.data },
        updated: Date.now(),
      });
      setIsLoading(false);
      setIsExists(true);
      const { akunFacebook, urlFacebook } = result.data.data
      setFacebookCheck({
        ...fbCheck,
        isfbexists: !!akunFacebook.trim() && !!urlFacebook.trim(),
      });
    } else {
      setIsLoading(false);
      setIsExists(false);
    }
  }

  const value = {
    token, idMember,
    account: reduxAccount?.data,
    isLoading,
    setIsLoading,
    isExists,
    setIsExists,
    getAccount,
    setAccount,
  }

  useEffect(() => {
    if (isLogin) {
      if (!Object.keys(reduxAccount.data).length > 0) {
        getAccount();
      } else {
        if (isNeedUpdate(reduxAccount)) {
          getAccount();
        }
        setIsLoading(false);
      }
    }
  }, [isLogin, reduxAccount])

  return (
    <AccountContext.Provider
      value={value}
    >
      {children}
    </AccountContext.Provider>
  )
}

const mapDispatchToProps = dispatch => {
  return {
    setAccount: (account) =>
      dispatch({
        type: ActionType.SET_ACCOUNT,
        payload: { ...account }
      }),
    logout: () => dispatch({ type: ActionType.LOGOUT }),
    setFacebookCheck: (setting) =>
      dispatch({
        type: ActionType.SET_FB_CHECK,
        payload: setting,
      }),
  }
}

const mapStateToProps = state => {
  return {
    isLogin: state.isLogin,
    idMember: state.idMember,
    token: state.token,
    reduxAccount: state.account,
    fbCheck: state.fbcheck,
  }
}

export const AccountProvider = connect(mapStateToProps, mapDispatchToProps)(AccountProviderComponent);

export const useAccount = () => useContext(AccountContext)
