import Get from '../methods/Get';

//  Suppliers
// get supplier by username/id
const getSupplier = (username, token) => Get(`suppliers/${username}`, (token == null) ? '' : { "Authorization": `Bearer ${token}` });

// const getListingSupplier = () => Get(`best_dropship`);
const getListingSupplier = (url, token) => Get(url, (token == null) ? '' : { "Authorization": `Bearer ${token}` });

// const getListingSupplier = (url, token) => callAPI(url, token);

export const supplier = {
    getSupplier,
    getListingSupplier
}