import Get from '../methods/Get';
import Post from '../methods/Post';

const getPaylaterDashboard = ({
  queryParams,
  token
}) => Get(`paylater_dashboard?${queryParams}`, { "Authorization": `Bearer ${token}` });

const getPaylaterPaymentHistory = ({
  limit,
  page,
  searchKeyword,
  token,
}) => {
  const options = { limit, page, searchKeyword };
  const queryParams = Object.keys(options)
    .filter(key => options[key])
    .map(key =>
      `${encodeURIComponent(key)}=${encodeURIComponent(options[key])}`,
    )
    .join('&');
  const url = `paylater_payment_history?${queryParams}`;
  const headers = { Authorization: `Bearer ${token}` };

  return Get(url, headers);
}

const getPaylaterMustPayList = ({
  limit,
  page,
  searchKeyword,
  token,
}) => {
  const options = { limit, page, searchKeyword };
  const queryParams = Object.keys(options)
    .filter(key => options[key])
    .map(key =>
      `${encodeURIComponent(key)}=${encodeURIComponent(options[key])}`,
    )
    .join('&');
  const url = `list_paylater_must_pay?${queryParams}`;
  const headers = { Authorization: `Bearer ${token}`};

  return Get(url, headers);
};

const getInvoicePaylater = ({
  id,
  token
}) => Get(`invoice-paylater/${id}`, { "Authorization": `Bearer ${token}` });

const getPaylaterBalance = ({
  queryParams,
  token
}) => Get(`paylater_balance${queryParams ? `?${queryParams}` : ''}`, { "Authorization": `Bearer ${token}` });

const postPaylaterDebt = ({ token, data }) => Post('pay_debt_paylater', data, token);

export const paylaters = {
  getPaylaterPaymentHistory,
  getPaylaterDashboard,
  getPaylaterMustPayList,
  getInvoicePaylater,
  getPaylaterBalance,
  postPaylaterDebt,
}

