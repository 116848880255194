import Get from '../methods/Get';
import Post from '../methods/Post';

// // ================== Voucher Store ==================
// // Get Store Voucher 
const voucherStore = token => Get('voucher-store', { "Authorization": `Bearer ${token}` });

// Get Voucher Products 
const getVouchers = (params, token) => Get(`vouchers${params}`, { "Authorization": `Bearer ${token}` });

// Add Voucher Cart
const addVoucherCart = (data, token) => Post('voucher-cart', data, token);

// Checkout Buy Voucher
const checkoutBuyVoucher = (data, token) => Post('voucher-checkout', data, token);

// Get voucher balance
const getVoucherBalance = (id, token) => Get(`voucher/${id}/balance`, { "Authorization": `Bearer ${token}` });

// Get voucher mutation
const getVoucherMutation = (id, token, params) => Get(`voucher/${id}/mutation${params}`, { "Authorization": `Bearer ${token}` });

// Get voucher invoice
const getVoucherInvoice = (type, token) => Get(`voucher-invoice?type=${type}`, { "Authorization": `Bearer ${token}` });

// Download excel
const getVoucherExcel = (id, token, params) => Get(`voucher/${id}/mutation-excel${params}`, { "Authorization": `Bearer ${token}` });

const getHistoryVoucherInvoice = (url, token) => Get(url, { "Authorization": `Bearer ${token}` })

const getListVoucherUnpaid = (url, token) => Get(url, { "Authorization": `Bearer ${token}` })

export const voucher = {
  voucherStore,
  getVouchers,
  addVoucherCart,
  checkoutBuyVoucher,
  getVoucherBalance,
  getVoucherMutation,
  getVoucherInvoice,
  getVoucherExcel,
  getHistoryVoucherInvoice,
  getListVoucherUnpaid,
}