import Get from "../methods/Get";
import Post from "../methods/Post";

export const getPartnerOrderStatus = ({ token, cancelToken = null }) => Get(
  'partner-order-status',
  { Authorization: `Bearer ${token}`},
  cancelToken,
);

export const getPartnerOrders = ({ token, query = '', cancelToken = null }) => Get(
  `partner-orders?${query}`,
  { Authorization: `Bearer ${token}`},
  cancelToken,
);

export const postOrderAccept = ({ id, token }) => Post(`/partner-order-accept/${id}`, {}, token);

export const partnerOrders = { getPartnerOrderStatus, getPartnerOrders, postOrderAccept };
