import Get from '../methods/Get';


// Get home content
const getHome = (url, token) => Get(url, (token == null) ? '' : { "Authorization": `Bearer ${token}` });

// Get Display Categories
const categories = () => Get('categories?showAll=1', null);

// Autocomplete
const autoComplete = keyword => Get(`autocomplete?keyword=${keyword}`, null);

// Get Number CS
const getNumberCS = param => Get(`cs_number${param}`, null);

// Get running text
const getRunningText = type => Get(`running_text?type=${type}`, null);


export const homepage = {
    getHome,
    categories,
    autoComplete,
    getNumberCS,
    getRunningText,
}