import Get from '../methods/Get';
import Patch from '../methods/Patch';

// ================== Notification ==================
// Get news 
const newsNotification = token => Get('notifications-news', { "Authorization": `Bearer ${token}` });

// Read news
const readNotification = (id, token) => Patch(`read-notification/${id}`, null, token);

// Get all notifications
const getNotifications = (params, token) => Get(`notifications${params}`, { "Authorization": `Bearer ${token}` })

export const notification = {
    newsNotification,
    readNotification,
    getNotifications
}