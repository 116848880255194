import Get from '../methods/Get';
import Post from '../methods/Post';

// ================== ORDERS ==================
const listOrder = (token, params, cancelToken) => Get(`orders${params}`, { "Authorization": `Bearer ${token}` }, cancelToken);

const listOrderLandingPage = (token, params) => Get(`lp/orders${params}`, { "Authorization": `Bearer ${token}` });

const exportExcel = (token, params) => Get(`orders/export-excel${params}`, { "Authorization": `Bearer ${token}` });

const detailOrder = (id, token) => Get(`orders/${id}/detail`, { "Authorization": `Bearer ${token}` });

const detailOrderLandingPage = (id, token) => Get(`lp/orders/${id}`, { "Authorization": `Bearer ${token}` });

const trackingShipping = (id, number, courier, token) => Get(`get_waybill/${id}?resi=${number}&courier=${courier}`, { "Authorization": `Bearer ${token}` });

const postPriceSell = (token, data) => Post('checkout/upload-file-sell-price', data, token);

const postFileUploadCheckout = (url, data, token) => Post(url, data, token);

const trackingShippingLp = (id, number, courier, token) => Get(`get_waybill_lp/${id}?resi=${number}&courier=${courier}`, { "Authorization": `Bearer ${token}` });

const reportSeller = (url, data, token) => Post(url, data, token);

export const orders = {
  listOrder,
  listOrderLandingPage,
  detailOrder,
  detailOrderLandingPage,
  trackingShipping,
  postPriceSell,
  postFileUploadCheckout,
  exportExcel,
  trackingShippingLp,
  reportSeller,
}

