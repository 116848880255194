import React from 'react';
import MainPage from '../src/container/MainPage/MainPage';

const Baleomol = () => {
  return (
    <MainPage />
  )
}

export default Baleomol;
