import loadable from "@loadable/component";
import jwt from "jsonwebtoken";
import moment from "moment";
import React, { Suspense, useEffect } from "react";
import ReactPixel from "react-facebook-pixel";
import { default as ReactGA, default as ReactGA2 } from "react-ga";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import { useAccount } from "../hooks/useAccount";
import ActionType from "../redux/reducer/globalActionType";
import ContentLoader from "./ContentLoader";

const HomeAcademy = loadable(() => import("../academy/pages/Home"));
const AboutUs = loadable(() => import("../academy/pages/AboutUs"));
const CourseRecommendation = loadable(() =>
  import("../academy/pages/CourseRecommendation")
);
const RegisterCourse = loadable(() =>
  import("../academy/pages/RegisterCourse")
);
// const HomePageCourse = loadable(() => import("../course/pages/Home"));
const Course = loadable(() => import("../academy/pages/Course"));
const MyAccount = loadable(() => import("../academy/pages/MyAccount"));
const MyAccountSecurity = loadable(() =>
  import("../academy/pages/MyAccount/Security")
);
const VerifyAccount = loadable(() => import("../academy/pages/VerifyAccount"));
const FindCourse = loadable(() => import("../academy/pages/FindCourse"));
const CourseHistoryTransaction = loadable(() =>
  import("../academy/pages/Course/HistoryTransaction")
);
const CourseV2 = loadable(() => import("../academy/pages/CourseV2"));
const CourseDetail = loadable(() => import("../academy/pages/Course/Detail"));
const CourseCheckout = loadable(() =>
  import("../academy/pages/Course/Checkout")
);
const CourseInvoice = loadable(() => import("../academy/pages/Course/Invoice"));
// const CoursePayout = loadable(() =>
//   import("../course/pages/Course/Payout_2/Payout")
// );
// const CoursePayout = loadable(() => import('../course/pages/Course/Payout'));
// const CoursePayment = loadable(() => import('../course/pages/Course/Payment'));
const Store = loadable(() => import("../pages/Store"));
// const RegisterSupplier = loadable(() => import('../pages/RegisterSupplier'));

const Detail = loadable(() =>
  import("../container/pages/Orders/organims/Detail/Detail")
);
const DetailLandingPage = loadable(() =>
  import(
    "../container/pages/OrdersLandingPage/organims/Detail/DetailLandingPage"
  )
);
const Settings = loadable(() => import("../container/pages/Settings"));
const ReleaseNotes = loadable(() => import("../container/pages/ReleaseNotes"));
const VideoTutorialSystem = loadable(() =>
  import("../container/pages/VideoTutorialSystem")
);
const ExpiredDomains = loadable(() =>
  import("../container/pages/MyLandingPageDomain/organisms/ExpiredDomains")
);
const Feeds = loadable(() => import("../container/pages/Feeds"));
const BulkOrders = loadable(() => import("../container/pages/BulkOrders"));
const PaylaterPayment = loadable(() =>
  import("../container/pages/Paylater/PaylaterPayment")
);
const PaylaterPaymentHistory = loadable(() =>
  import("../container/pages/Paylater/PaylaterPaymentHistory")
);
const PaylaterDashboard = loadable(() =>
  import("../container/pages/Paylater/PaylaterDashboard")
);
const PaylaterInvoiceConfirm = loadable(() =>
  import("../container/pages/Paylater/PaylaterInvoiceConfirm")
);
const MitraOrders = loadable(() => import("../container/pages/MitraOrders"));
const Mitra = loadable(() => import("../container/pages/Mitra"));
// const LandingPageSupplierV2 = loadable(() => import('../container/pages/LandingPageSupplierV2'));
const ProductV2 = loadable(() => import("../container/pages/ProductV2"));
const ProductRecomendation = loadable(() =>
  import("../container/pages/ProductRecomendation")
);
const HomePage = loadable(() => import("../container/pages/HomePage/HomePage"));
const Community = loadable(() =>
  import("../container/pages/Community/Community")
);
const Account = loadable(() => import("../container/pages/Account/Account"));
const Product = loadable(() => import("../container/pages/Product/Product"));
const Orders = loadable(() => import("../container/pages/Orders/Orders"));
const OrdersLandingPage = loadable(() =>
  import("../container/pages/OrdersLandingPage/OrdersLandingPage")
);
const MultiBuy = loadable(() => import("../container/pages/MultiBuy/MultiBuy"));
const ProductsByCategory = loadable(() =>
  import("../container/pages/ProductsByCategory/ProductsByCategory")
);
const ProductsBySearch = loadable(() =>
  import("../container/pages/ProductsBySearch/ProductsBySearch")
);
const Login = loadable(() => import("../container/pages/Login/Login"));
const LandingDropshipper = loadable(() =>
  import("../container/pages/LandingPage/Dropshipper/Dropshipper")
);
// const LandingLP = loadable(() => import('../container/pages/LandingPage/LandingPage/LandingPage'));
const Checkout = loadable(() => import("../container/pages/Checkout/Checkout"));
// const Register = loadable(() => import('../container/pages/Register/Dropshipper/Register'));
const RegisterInvoice = loadable(() =>
  import("../container/pages/RegisterInvoice/RegisterInvoice")
);
const Invoice = loadable(() => import("../container/pages/Invoice/Invoice"));
const ConfirmInvoice = loadable(() =>
  import("../container/pages/ConfirmInvoice/ConfirmInvoice")
);
const ForgotPassword = loadable(() =>
  import("../container/pages/ForgotPassword/ForgotPassword")
);
const ResetPassword = loadable(() =>
  import("../container/pages/ResetPassword/ResetPassword")
);
const Wallet = loadable(() => import("../container/pages/Wallet/Wallet"));
const VideoTutorial = loadable(() =>
  import("../container/pages/VideoTutorial/VideoTutorial")
);
const Notification = loadable(() =>
  import("../container/pages/Notification/Notification")
);
const Discuss = loadable(() => import("../container/pages/Discuss/Discuss"));
const VoucherStore = loadable(() =>
  import("../container/pages/VoucherStore/VoucherStore")
);
const VoucherDetail = loadable(() =>
  import("../container/pages/VoucherDetail/VoucherDetail")
);
const VoucherCheckout = loadable(() =>
  import("../container/pages/VoucherCheckout/VoucherCheckout")
);
const Scrape = loadable(() => import("../container/pages/Scrape/Scrape"));
const CheckoutInstant = loadable(() =>
  import("../container/pages/CheckoutInstant/CheckoutInstant")
);
// const MyLandingPage = loadable(() => import('../container/pages/MyLandingPage/MyLandingPage'));
// const MyLandingPageRegister = loadable(() => import('../container/pages/MyLandingPageRegister/MyLandingPageRegister'));
// const MyLandingPageDomain = loadable(() => import('../container/pages/MyLandingPageDomain/MyLandingPageDomain'));
// const RegisterBundle = loadable(() => import('../container/pages/RegisterBundle/RegisterBundle'));
const InvoiceLP = loadable(() =>
  import("../container/pages/InvoiceLP/InvoiceLP")
);
const ConfirmInvoiceLP = loadable(() =>
  import("../container/pages/ConfirmInvoiceLP/ConfirmInvoiceLP")
);
// const DiscussOrderLP = loadable(() => import('../container/pages/DiscussOrderLP/DiscussOrderLP'));
const LogoutAllDevice = loadable(() =>
  import("../container/pages/LogoutAllDevice/LogoutAllDevice")
);
const DashboardRTS = loadable(() =>
  import("../container/pages/DashboardRTS/DashboardRTS")
);
const HistoryPayRTS = loadable(() =>
  import("../container/pages/HistoryPayRTS/HistoryPayRTS")
);
const PayRTS = loadable(() => import("../container/pages/PayRTS/PayRTS"));
// const SubmissionRTS = loadable(() => import('../container/pages/SubmissionRTS/SubmissionRTS'));
const ConfirmInvoiceRTS = loadable(() =>
  import("../container/pages/ConfirmInvoiceRTS/ConfirmInvoiceRTS")
);
const ApplyRTS = loadable(() => import("../container/pages/ApplyRTS/ApplyRTS"));
const ForbiddenAccess = loadable(() =>
  import("../container/pages/ForbiddenAccess/ForbiddenAccess")
);
const ListingSupplier = loadable(() =>
  import("../container/pages/ListingSupplier/ListingSupplier")
);
const Webinar = loadable(() => import("../container/pages/Webinar/Webinar"));
const PrivacyPolicy = loadable(() =>
  import("../container/pages/PrivacyPolicy")
);
const ErrorPage = loadable(() =>
  import("../container/pages/ErrorPage/ErrorPage")
);
// const DiscussOrderLP2 = loadable(() => import('../container/pages/DiscussOrderLP2'));
const DetailPaymentRTS = loadable(() =>
  import("../container/pages/DetailPaymentRTS/DetailPaymentRTS")
);
const RegisterWhatsapp = loadable(() =>
  import("../container/pages/RegisterWhatsapp")
);
const FaqNew = loadable(() => import("../container/pages/FaqNew"));
const MyVoucher = loadable(() => import("../container/pages/MyVoucher"));
const MPReportSeller = loadable(() =>
  import("../container/pages/ReportSeller/MPReportSeller")
);
const PBReportSeller = loadable(() =>
  import("../container/pages/ReportSeller/PBReportSeller")
);
const DashboardMarketplace = loadable(() =>
  import("../container/pages/Dashboard/DashboardMarketplace")
);
const DashboardLandingpage = loadable(() =>
  import("../container/pages/Dashboard/DashboardLandingpage")
);

const TRACKING_ID = "UA-178435587-1"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

const TRACKING_ID2 = "AW-10868522454"; // OUR_TRACKING_ID
ReactGA2.initialize(TRACKING_ID2);

// const FACEBOOK_ID = "511534716600280";
// const options = {
//   autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
//   debug: false, // enable logs
// };

// ReactPixel.init(FACEBOOK_ID, null, options);

const MiddlewareCourse = ({ component: Component, authed, ...rest }) => {
  return (
    <>
      {authed && (
        <>
          <Route {...rest} render={(props) => <Component {...props} />} />
        </>
      )}

      {typeof authed === "undefined" && (
        <Route
          {...rest}
          render={(props) => (
            <Redirect
              to={{ pathname: "/login", state: { from: props.location } }}
            />
          )}
        />
      )}
    </>
  );
};

const MiddlewareMarketplace = ({
  component: Component,
  authed,
  editorAccess,
  role,
  csAccess = false,
  ...rest
}) => {
  const { account } = useAccount((state) => state);
  return (
    <>
      {/* IsMpActive */}
      {!account?.isMpActive && (
        <>
          <Route
            {...rest}
            render={(props) => (
              <Redirect
                to={{ pathname: "/", state: { from: props.location } }}
              />
            )}
          />
        </>
      )}

      {/* Anonymous */}
      {role === "anonymous" && (
        <>
          <Route {...rest} render={(props) => <Component {...props} />} />
        </>
      )}

      {/* Editor */}
      {role === "lp_editor" && (
        <>
          <Route
            {...rest}
            render={(props) =>
              editorAccess && authed ? (
                <Component {...props} />
              ) : (
                <Redirect
                  to={{
                    pathname: "/forbidden-access",
                    state: { from: props.location },
                  }}
                />
              )
            }
          />
        </>
      )}

      {/* CS */}
      {role === "lp_cs" && (
        <>
          <Route
            {...rest}
            render={(props) =>
              csAccess && authed ? (
                <Component {...props} />
              ) : (
                <Redirect
                  to={{
                    pathname: "/forbidden-access",
                    state: { from: props.location },
                  }}
                />
              )
            }
          />
        </>
      )}

      {/* Member */}
      {role === "member" && (
        <Route
          {...rest}
          render={(props) =>
            authed ? (
              <Component {...props} />
            ) : (
              <Redirect
                to={{ pathname: "/login", state: { from: props.location } }}
              />
            )
          }
        />
      )}

      {role !== "anonymous" && typeof authed === "undefined" && (
        <Route
          {...rest}
          render={(props) => (
            <Redirect
              to={{ pathname: "/login", state: { from: props.location } }}
            />
          )}
        />
      )}
    </>
  );
};

const Routing = (props) => {
  const location = useLocation();
  let history = useHistory();
  const { setIsFeedsPage } = props;

  useEffect(() => {
    if (props.token) {
      const exp = jwt.decode(props.token).exp;
      const current = moment().unix();

      if (exp && current) {
        if (exp < current) {
          props.logout();
          history.push("/");
        }
      }
    }
  }, [location, props.token]); // eslint-disable-line

  useEffect(() => {
    const page = window.location.pathname;
    setIsFeedsPage(Boolean(page.match(/^\/mp\/feeds/g)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
    ReactGA2.pageview(window.location.pathname + window.location.search);
    ReactPixel.pageView();
  }, [location]);

  return (
    <Suspense fallback={<ContentLoader />}>
      <Switch>
        <Route exact path="/" component={HomeAcademy} />
        <Route exact path="/tentang-kami" component={AboutUs} />
        <Route exact path="/kelas-kami" component={CourseRecommendation} />
        <Route exact path="/verify-account" component={VerifyAccount} />
        <MiddlewareCourse
          exact
          authed={props.isLogin}
          path="/search"
          component={FindCourse}
        />
        <MiddlewareCourse
          exact
          authed={props.isLogin}
          path="/my-account"
          component={MyAccount}
        />
        <MiddlewareCourse
          exact
          authed={props.isLogin}
          path="/my-account/security"
          component={MyAccountSecurity}
        />
        <MiddlewareCourse
          exact
          authed={props.isLogin}
          path="/course"
          component={Course}
        />
        <MiddlewareCourse
          authed={props.isLogin}
          path="/course/detail/:id"
          component={CourseV2}
        />
        <MiddlewareCourse
          exact
          authed={props.isLogin}
          path="/course"
          component={Course}
        />
        <MiddlewareCourse
          exact
          authed={props.isLogin}
          path="/course/history-transaction"
          component={CourseHistoryTransaction}
        />
        <MiddlewareCourse
          exact
          authed={props.isLogin}
          path="/course/v1/detail/:id"
          component={CourseDetail}
        />
        {/* <Route exact path="/course/payout/:classId" component={CoursePayout} /> */}
        <Route
          exact
          path="/course/checkout/:classId"
          component={CourseCheckout}
        />
        <MiddlewareCourse
          exact
          authed={props.isLogin}
          path="/course/invoice/:invoiceId"
          component={CourseInvoice}
        />
        <MiddlewareMarketplace
          exact
          authed={props.isLogin}
          role={props.userRole}
          editorAccess={false}
          path="/marketplace"
          component={HomePage}
        />
        <Route exact path="/dropshipper" component={LandingDropshipper} />
        <Route exact path="/login" component={Login} />
        <MiddlewareMarketplace
          exact
          authed={props.isLogin}
          role={props.userRole}
          editorAccess={false}
          path="/mp/faq"
          component={FaqNew}
        />
        <MiddlewareMarketplace
          exact
          authed={props.isLogin}
          role={props.userRole}
          editorAccess={false}
          path="/mp/release-notes"
          component={ReleaseNotes}
        />
        <MiddlewareMarketplace
          exact
          authed={props.isLogin}
          role={props.userRole}
          editorAccess={false}
          path="/mp/community"
          component={Community}
        />
        <Route exact path="/register" component={RegisterCourse} />
        <Route exact path="/inv-reg/confirm/:id" component={RegisterInvoice} />
        <Route exact path="/forgot-password" component={ForgotPassword} />
        <Route exact path="/reset-password" component={ResetPassword} />
        <Route
          exact
          path="/invoice-lp-confirm/:id"
          component={ConfirmInvoiceLP}
        />
        <Route exact path="/log-out-all-devices" component={LogoutAllDevice} />
        <Route exact path="/forbidden-access" component={ForbiddenAccess} />
        {/* Disable Discuss */}
        <Route exact path="/discuss" component={Discuss} />
        <Route exact path="/discuss/:id" component={Discuss} />
        {/* <Route exact path='/discuss-order-lp/detail/:id' component={DiscussOrderLP} /> */}
        {/* <Route exact path='/discuss-order-lp' component={DiscussOrderLP2} /> */}
        {/* <Route exact path='/discuss-order-lp/:id' component={DiscussOrderLP2} /> */}
        <Route
          exact
          path="/seller"
          component={() => {
            window.location.href = "https://dash.baleomol.com/seller";
            return null;
          }}
        />
        <Route
          exact
          path="/admstr"
          component={() => {
            window.location.href = "https://dash.baleomol.com/admstr";
            return null;
          }}
        />
        <MiddlewareMarketplace
          exact
          authed={props.isLogin}
          role={props.userRole}
          editorAccess={false}
          path="/mp/listing-supplier"
          component={ListingSupplier}
        />
        <MiddlewareMarketplace
          authed={props.isLogin}
          role={props.userRole}
          editorAccess={false}
          path="/mp/mitra"
          component={Mitra}
        />
        <Route exact path="/privacy-policy" component={PrivacyPolicy} />
        <Route exact path="/register-whatsapp" component={RegisterWhatsapp} />
        <MiddlewareMarketplace
          exact
          authed={props.isLogin}
          role={props.userRole}
          editorAccess={false}
          path="/mp/webinar"
          component={Webinar}
        />
        <MiddlewareMarketplace
          authed={props.isLogin}
          role={props.userRole}
          editorAccess={false}
          path="/mp/store"
          component={Store}
        />
        <MiddlewareMarketplace
          exact
          authed={props.isLogin}
          role={props.userRole}
          editorAccess={false}
          path="/mp/notification/:type"
          component={Notification}
        />
        <MiddlewareMarketplace
          exact
          authed={props.isLogin}
          role={props.userRole}
          editorAccess={true}
          path="/mp/category/:id/:page?"
          component={ProductsByCategory}
        />
        <MiddlewareMarketplace
          exact
          authed={props.isLogin}
          role={props.userRole}
          editorAccess={true}
          path="/mp/search/:keyword"
          component={ProductsBySearch}
        />
        <MiddlewareMarketplace
          exact
          authed={props.isLogin}
          role={props.userRole}
          editorAccess={false}
          path="/mp/cart"
          component={MultiBuy}
        />
        <MiddlewareMarketplace
          authed={props.isLogin}
          role={props.userRole}
          editorAccess={false}
          path="/mp/checkout"
          component={Checkout}
        />
        <MiddlewareMarketplace
          authed={props.isLogin}
          role={props.userRole}
          editorAccess={false}
          path="/mp/checkout-instant"
          component={CheckoutInstant}
        />
        <MiddlewareMarketplace
          exact
          authed={props.isLogin}
          role={props.userRole}
          editorAccess={false}
          path="/mp/invoice/:id"
          component={Invoice}
        />
        <MiddlewareMarketplace
          exact
          authed={props.isLogin}
          role={props.userRole}
          editorAccess={false}
          path="/mp/invoice-confirm/:id"
          component={ConfirmInvoice}
        />
        <MiddlewareMarketplace
          exact
          authed={props.isLogin}
          role={props.userRole}
          editorAccess={false}
          path="/mp/invoice-lp/:id"
          component={InvoiceLP}
        />
        <MiddlewareMarketplace
          exact
          authed={props.isLogin}
          role={props.userRole}
          editorAccess={false}
          path="/mp/orders/:slug/:page"
          component={Orders}
        />
        <MiddlewareMarketplace
          exact
          authed={props.isLogin}
          role={props.userRole}
          editorAccess={false}
          path="/mp/order/report-seller/:identifier"
          component={MPReportSeller}
        />
        <MiddlewareMarketplace
          exact
          authed={props.isLogin}
          role={props.userRole}
          editorAccess={false}
          csAccess
          path="/mp/order-landing-page/report-seller/:identifier"
          component={PBReportSeller}
        />
        <MiddlewareMarketplace
          exact
          authed={props.isLogin}
          role={props.userRole}
          editorAccess={false}
          csAccess
          path="/mp/orders-landing-page/:slug/:page"
          component={OrdersLandingPage}
        />
        <MiddlewareMarketplace
          exact
          authed={props.isLogin}
          role={props.userRole}
          editorAccess={false}
          path="/mp/extend-landing-page-domains"
          component={ExpiredDomains}
        />
        <MiddlewareMarketplace
          exact
          authed={props.isLogin}
          role={props.userRole}
          editorAccess={false}
          path="/mp/order/detail/:id"
          component={Detail}
        />
        <MiddlewareMarketplace
          exact
          authed={props.isLogin}
          role={props.userRole}
          editorAccess={false}
          csAccess
          path="/mp/order-landing-page/detail/:id"
          component={DetailLandingPage}
        />
        <Route path="/settings/*" component={Settings} />
        <MiddlewareMarketplace
          authed={props.isLogin}
          role={props.userRole}
          editorAccess={false}
          path="/mp/account"
          component={Account}
        />
        <MiddlewareMarketplace
          exact
          authed={props.isLogin}
          role={
            typeof props.userRole !== "undefined" ? props.userRole : "anonymous"
          }
          editorAccess={true}
          csAccess
          path="/mp/product/:id/:link"
          component={Product}
        />
        <MiddlewareMarketplace
          exact
          authed={props.isLogin}
          role={
            typeof props.userRole !== "undefined" ? props.userRole : "anonymous"
          }
          editorAccess={true}
          csAccess
          path="/product-v2/:id/:link"
          component={ProductV2}
        />
        <MiddlewareMarketplace
          exact
          authed={props.isLogin}
          role={props.userRole}
          editorAccess={false}
          path="/mp/wallet/:page"
          component={Wallet}
        />
        <MiddlewareMarketplace
          exact
          authed={props.isLogin}
          role={props.userRole}
          editorAccess={false}
          path="/mp/video-tutorial/:type"
          component={VideoTutorial}
        />
        <MiddlewareMarketplace
          exact
          authed={props.isLogin}
          role={props.userRole}
          editorAccess={false}
          path="/mp/video-tutorial-system"
          component={VideoTutorialSystem}
        />
        <MiddlewareMarketplace
          exact
          authed={props.isLogin}
          role={props.userRole}
          editorAccess={false}
          path="/mp/my-voucher/:page"
          component={MyVoucher}
        />
        <MiddlewareMarketplace
          exact
          authed={props.isLogin}
          role={props.userRole}
          editorAccess={false}
          path="/mp/voucher-store"
          component={VoucherStore}
        />
        <MiddlewareMarketplace
          exact
          authed={props.isLogin}
          role={props.userRole}
          editorAccess={false}
          path="/mp/voucher/:slug"
          component={VoucherDetail}
        />
        <MiddlewareMarketplace
          exact
          authed={props.isLogin}
          role={props.userRole}
          editorAccess={false}
          path="/mp/checkout-voucher"
          component={VoucherCheckout}
        />
        <MiddlewareMarketplace
          exact
          authed={props.isLogin}
          role={props.userRole}
          editorAccess={false}
          path="/mp/dashboard/marketplace"
          component={DashboardMarketplace}
        />
        <MiddlewareMarketplace
          exact
          authed={props.isLogin}
          role={props.userRole}
          editorAccess={false}
          path="/mp/dashboard/landingpage"
          component={DashboardLandingpage}
        />
        <MiddlewareMarketplace
          exact
          authed={props.isLogin}
          role={props.userRole}
          editorAccess={false}
          path="/mp/scrape"
          component={Scrape}
        />
        <MiddlewareMarketplace
          exact
          authed={props.isLogin}
          role={props.userRole}
          editorAccess={false}
          path="/mp/dashboard-rts"
          component={DashboardRTS}
        />
        <MiddlewareMarketplace
          exact
          authed={props.isLogin}
          role={props.userRole}
          editorAccess={false}
          path="/mp/rts/history-pay"
          component={HistoryPayRTS}
        />
        <MiddlewareMarketplace
          exact
          authed={props.isLogin}
          role={props.userRole}
          editorAccess={false}
          path="/mp/rts/apply"
          component={ApplyRTS}
        />
        <MiddlewareMarketplace
          exact
          authed={props.isLogin}
          role={props.userRole}
          editorAccess={false}
          path="/mp/pay-rts"
          component={PayRTS}
        />
        ;
        {/* <MiddlewareMarketplace
          exact
          authed={props.isLogin}
          role={props.userRole}
          editorAccess={false}
          path='/mp/rts/submission'
          component={SubmissionRTS}
        /> */}
        <MiddlewareMarketplace
          exact
          authed={props.isLogin}
          role={props.userRole}
          editorAccess={false}
          path="/mp/inv-rts/confirm/:id"
          component={ConfirmInvoiceRTS}
        />
        <MiddlewareMarketplace
          exact
          authed={props.isLogin}
          role={props.userRole}
          editorAccess={false}
          path="/mp/rts/payment/:id"
          component={DetailPaymentRTS}
        />
        <MiddlewareMarketplace
          exact
          authed={props.isLogin}
          role={props.userRole}
          editorAccess={false}
          path="/mp/feeds"
          component={Feeds}
        />
        <MiddlewareMarketplace
          exact
          authed={props.isLogin}
          role={props.userRole}
          editorAccess={false}
          path="/mp/bulk-order/:slug/:page"
          component={BulkOrders}
        />
        <MiddlewareMarketplace
          exact
          authed={props.isLogin}
          role={props.userRole}
          editorAccess={false}
          path="/mp/mitra-order/:slug/:page"
          component={MitraOrders}
        />
        <MiddlewareMarketplace
          exact
          authed={props.isLogin}
          role={props.userRole}
          editorAccess={false}
          path="/mp/paylater/dashboard"
          component={PaylaterDashboard}
        />
        <MiddlewareMarketplace
          exact
          authed={props.isLogin}
          role={props.userRole}
          editorAccess={false}
          path="/mp/paylater/payment-history"
          component={PaylaterPaymentHistory}
        />
        <MiddlewareMarketplace
          exact
          authed={props.isLogin}
          role={props.userRole}
          editorAccess={false}
          path="/mp/paylater/payment"
          component={PaylaterPayment}
        />
        <MiddlewareMarketplace
          exact
          authed={props.isLogin}
          role={props.userRole}
          editorAccess={false}
          path="/mp/paylater/invoice/confirm/:code"
          component={PaylaterInvoiceConfirm}
        />
        <MiddlewareMarketplace
          exact
          authed={props.isLogin}
          role={props.userRole}
          editorAccess={false}
          path="/mp/product-recommendation/:slug?"
          component={ProductRecomendation}
        />
        <Route path="*" component={ErrorPage} />
        <Route path="/404" component={ErrorPage} />
      </Switch>
    </Suspense>
  );
};

const mapStateToProps = (state) => {
  return {
    isLogin: state.isLogin,
    deviceType: state.tpdvc,
    screen: state.tpdvc,
    token: state.token,
    isEditorLP: state.isedtrlp,
    isCSLP: state.iscslp,
    userRole: state.userrole,
    // template: state.template
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch({ type: ActionType.LOGOUT }),
    setIsFeedsPage: (isFeeds) =>
      dispatch({
        type: ActionType.SET_IS_FEEDS_PAGE,
        payload: isFeeds,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Routing);
