import Get from '../methods/Get';
import Post from '../methods/Post';


// Get wallet member
const getWallet = token => Get('wallets/balance', { "Authorization": `Bearer ${token}` })

// Get wallet mutation
const getWalletMutation = ( token, params ) => Get(`wallets/mutation${params}`, { "Authorization": `Bearer ${token}` })

// Get Passive Income
const getPassiveIncome = ( id, token ) => Get(`member/${id}/passive-income`, {"Authorization": `Bearer ${token}`});

// Get Affiliate List
const getAffiliateList = ( id, params, token ) => Get(`member/${id}/affiliate-list${params}`, {"Authorization": `Bearer ${token}`});

// Get Submentor List
const getSubmentorList = ( id, params, token ) => Get(`member/${id}/submentor-list${params}`, {"Authorization": `Bearer ${token}`});

// Get passive income detail
const getPassiveIncomeDetail = (id, params, token) => Get(`passive-income/${id}/detail${params}`, {"Authorization": `Bearer ${token}`});

// Post withdrawal dana
const postWithdrawalDana = (data, token) => Post(`withdrawal-dana`, data, token);

// Post withdrawal bonus
const postWithdrawalBonus = (data, token) => Post(`withdrawal-bonus`, data, token);

// Get last withdrawal time
const getLastWithdrawal = ({ token }) => Get('withdrawal-last', { Authorization: `Bearer ${token}` });

export const wallet = {
    getWallet,
    getWalletMutation,
    getPassiveIncome,
    getAffiliateList,
    getSubmentorList,
    getPassiveIncomeDetail,
    postWithdrawalDana,
    postWithdrawalBonus,
    getLastWithdrawal,
}