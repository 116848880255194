import Get from '../methods/Get';
import Post from '../methods/Post';

const getRegisterSupplierOTPPhone = (phone) => Get(`otp-phone?phone=${phone}`);
const postRegisterSupplierOTPPhone = (data) => Post(`otp-phone`, data);

const getRegisterSupplierOTP = (params) => {
  const url = new URLSearchParams(params).toString()
  return Get(`otp-confirm?${url}`)
};

const postRegisterSupplierOTP = (data) => {
  return Post(`otp-confirm`, data)
};

const getEmailValidity = (params) => {
  const url = new URLSearchParams(params).toString()
  return Get(`check-valid-email?${url}`)
}

export const registerSuppliers = {
  getRegisterSupplierOTPPhone,
  postRegisterSupplierOTPPhone,
  getRegisterSupplierOTP,
  postRegisterSupplierOTP,
  getEmailValidity,
}