import axios from "axios"
import { Backend } from '../Config'

const Post = (path, data, token) => {
  let body;
  if (token !== undefined) {
    body = {
      method: "post",
      url: `${Backend}/${path}`,
      data: data,
      headers: { "Authorization": `Bearer ${token}` }
    }
  } else {
    body = {
      method: "post",
      url: `${Backend}/${path}`,
      data: data,
    }
  }

  const promise = new Promise((resolve, reject) => {
    axios(body).then((result) => {
      resolve(result)
    }, (err) => {
      reject(err)
    })
  })
  return promise;
}

export default Post;