/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useContext, useEffect, useState } from 'react';
import ReactPixel from 'react-facebook-pixel';
import { getMetaFromServer } from '../utils/MetaPixel';
import useAxiosFunction from './useAxiosFunction';
const MetaPixelContext = createContext(null)
const options = {
  autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  debug: false, // enable logs
};

export const MetaPixelProvider = ({ children }) => {
  const { dispatchData } = useAxiosFunction()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [metaPixelId, setMetaPixelId] = useState('')

  const fetchMetaPixelId = async () => {
    try {
      setIsLoading(true)
      const response = await dispatchData({
        url: `/pixel`,
        method: 'get',
      }).catch((err) => err.response);
      const message = response?.data?.message;
      const status = response?.status
      const success = response?.data
      if (response?.status > 300) {
        throw new Error(message || 'Error occured');
      }
      const data = response?.data?.data || [];
      setMetaPixelId(data?.pixel)
      setIsError(false)
    } catch (err) {
      console.log({ err })
      setIsError(true)
    } finally {
      setIsLoading(false)
    }
  }

  const track = (event, data) => {
    const metaServer = getMetaFromServer()
    if (metaServer) {
      console.log('Meta From Server,...', `Event: ${event}`)
      return metaServer(event, data)
    }
    console.log('Meta From Client,...', `Event: ${event}`)
    return ReactPixel.track(event, data)
  }

  useEffect(() => {
    !isLoading && !metaPixelId && fetchMetaPixelId()
  }, [])

  useEffect(() => {
    if (!isLoading && !isError && metaPixelId) {
      console.log('Init Meta Client')
      ReactPixel.init(metaPixelId, null, options);
      ReactPixel.pageView();
      return () => {
        ReactPixel.unload();
      };
    }
  }, [metaPixelId]);

  const value = {
    track
  };

  return (
    <MetaPixelContext.Provider value={value}>{children}</MetaPixelContext.Provider>
  )
}

export const useMetaPixel = () => useContext(MetaPixelContext)
