import axios from 'axios'
import { Backend } from '../Config'

const Delete = (path, header) => {
    const promise = new Promise((resolve, reject) => {
        axios.delete(`${Backend}/${path}`, { headers:  header  })
            .then((result) => {
                resolve(result)
            }, (err) => {
                reject(err)
            })
    })
    return promise;
}

export default Delete; 