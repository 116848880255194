import React from 'react';
import LazyLoad from 'react-lazy-load';
import useResponsiveAssets from '../../hooks/useResponsiveAssets';
import { useDefault } from '../../hooks/useDefault';

const ResponsiveImage = ({ src, alt = '', srcSet = {}, type = '', ratio = '', ...props }) => {
  const sourceWEBP = useResponsiveAssets({ src, breakpoints: srcSet, type, ratio, format: 'webp' })

  return (
    <LazyLoad>
      <img
        src={sourceWEBP}
        alt={alt}
        {...props}
        onError={(e) => {
          e.target.onerror = null;
          e.target.src = type.match(/product/gi) ? useDefault.getDefaultImageProduct : useDefault.getDefaultImage;
        }}
      />
    </LazyLoad >
  );
};

export default ResponsiveImage;