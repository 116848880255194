import Get from '../methods/Get';
import Post from '../methods/Post';

// ================== INVOICE ==================

//Post Invoice Image 
const invoiceImage = (id, token, data) => Post(`upload_proof_image/${id}`, data, token);

// Post Invoice Data
const postInvoice = (id, token, data) => Post(`upload_proof/${id}`, data, token);

// Get Invoice Register
const getInvoiceRegister = (id) => Get(`invoices/${id}`);

// Get invoice
const getInvoice = (id, token) => Get(`invoices/${id}`, { "Authorization": `Bearer ${token}` });

// Get invoice perpanjangan
const getInvoiceExtend = (token) => Get(`invoice-perpanjangan`, { "Authorization": `Bearer ${token}` });

//  Generate PDF Order
const getPDFOrder = (id, token) => Get(`orders/${id}/invoice-pdf`, { "Authorization": `Bearer ${token}` });

export const invoice = {
    invoiceImage,
    postInvoice,
    getInvoiceRegister,
    getInvoice,
    getPDFOrder,
    getInvoiceExtend,
}