import Get from '../methods/Get';
import Post from '../methods/Post';
import Patch from '../methods/Patch';

// Patch password
const changePassword = (id, data, token) => Patch(`members/${id}/password`, data, token);

// Get pin
const checkPin = (id, token) => Get(`members/${id}/pin`, {"Authorization": `Bearer ${token}`});

// Post pin
const addPin = (id, data, token) => Post(`members/${id}/pin`, data, token);

// Change pin
const changePin = (id, data, token) => Patch(`members/${id}/pin`, data, token);

// Reset pin
const resetPin = (id, data, token) => Patch(`members/${id}/reset_pin`, data, token);

// Check pin confirmation
const checkPinConfirm = ( data, token ) => Post(`check-pin`, data, token);

// Reset Password
const resetPassword = (data, token) => Post(`reset-password`, data, token);

// Forgot password
const forgotPassword = data => Post('forgot-password', data);

// Get OTP
const getOtp = (params, token) => Get(`otp${params}`, {"Authorization": `Bearer ${token}`});

// Post OTP
const postOtp = (data, token) => Post(`otp`, data, token);


export const password = {
    changePassword,
    checkPin,
    addPin,
    changePin,
    resetPin,
    checkPinConfirm,
    resetPassword,
    forgotPassword,
    getOtp,
    postOtp
}