import Delete from "../methods/Delete";
import Patch from "../methods/Patch";
import Post from "../methods/Post";
import Get from "../methods/Get";

const endpoints = {
  pocket: 'cart-bulk',
  product: 'cart-bulk-product',
  products: 'cart-bulk-products',
}

const getCartPockets = (token) => Get(
  endpoints.pocket,
  { Authorization: `Bearer ${token}` },
);

const getCartPocketsGroupBySupplier = (token) => Get(
  `${endpoints.pocket}/group-by-supplier`,
  { Authorization: `Bearer ${token}` },
);

const addCartPocket = (token) => Post(endpoints.pocket, {}, token);

const updateCartPocketByID = (id, data, token) => Patch(
  `${endpoints.pocket}/${id}`,
  data,
  token,
);

const deleteCartPocketByID = (id, token) => Delete(
  `${endpoints.pocket}/${id}`,
  { Authorization: `Bearer ${token}` },
);

const getCartProducts = (pocketId, token) => Get(
  `${endpoints.products}?cartId=${pocketId}`,
  { Authorization: `Bearer ${token}` },
);

const addCartProduct = (data, token) => Post(
  endpoints.product,
  data,
  token,
);

const updateCartProduct = (pocketId, id, data, token) => Patch(
  `${endpoints.product}?pocketId=${pocketId}&id=${id}`,
  data,
  token,
);

const deleteCartProduct = (pocketId, id, token) => Delete(
  `${endpoints.product}?pocketId=${pocketId}&id=${id}`,
  { Authorization: `Bearer ${token}` },
);

export const cartBulk = {
  getCartPocketsGroupBySupplier,
  getCartPockets,
  addCartPocket,
  updateCartPocketByID,
  deleteCartPocketByID,
  getCartProducts,
  addCartProduct,
  updateCartProduct,
  deleteCartProduct,
};
