import Delete from "../methods/Delete";
import Get from "../methods/Get";
import Patch from "../methods/Patch";
import Post from "../methods/Post";

const endpoints = {
  pocket: 'cart-partner',
  pockets: 'carts-partner',
  products: 'cart-partner-products',
}

const getPartnerCartPockets = (token) => Get(
  endpoints.pocket,
  { Authorization: `Bearer ${token}` },
);

const getDivinePartnerCartPockets = (token) => Get(
  endpoints.pockets,
  { Authorization: `Bearer ${token}`},
)

const addPartnerCartPocket = (partnerId, token) => Post(endpoints.pocket, { partnerId }, token);

const updatePartnerCartPocketByID = (id, data, token) => Patch(
  `${endpoints.pocket}/${id}`,
  data,
  token,
);

const deletePartnerCartPocketByID = (id, token) => Delete(
  `${endpoints.pocket}/${id}`,
  { Authorization: `Bearer ${token}` },
);

const getPartnerCartProducts = (pocketId, token) => Get(
  `${endpoints.products}?cartId=${pocketId}`,
  { Authorization: `Bearer ${token}` },
);

const addPartnerCartProduct = (data, token) => Post(
  endpoints.products,
  data,
  token,
);

const updatePartnerCartProduct = (id, data, token) => Patch(
  `${endpoints.products}/${id}`,
  data,
  token,
);

const deletePartnerCartProduct = (id, token) => Delete(
  `${endpoints.products}/${id}`,
  { Authorization: `Bearer ${token}` },
);

export const cartPartner = {
  getPartnerCartPockets,
  getDivinePartnerCartPockets,
  addPartnerCartPocket,
  updatePartnerCartPocketByID,
  deletePartnerCartPocketByID,
  getPartnerCartProducts,
  addPartnerCartProduct,
  updatePartnerCartProduct,
  deletePartnerCartProduct,
};
