/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import jwt from 'jsonwebtoken';
// import io from 'socket.io-client';
import { BrowserRouter, useLocation, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import ActionType from '../../redux/reducer/globalActionType';
import Routing from '../../routing';
// import ButtonChat from '../../components/ButtonChat/ButtonChat';
import { RefContext } from '../../hooks/useReferral';
import './Root.scss';
import { AccountProvider } from '../../hooks/useAccount';
import { ModalLoginProvider } from '../../hooks/useModalLogin';
import { createTheme } from '@material-ui/core';
import { MetaPixelProvider } from '../../hooks/useMetaPixel';
import { AccountVerifyProvider } from '../../hooks/useAccountVerify';
import ModalVerifyAccount from '../../pages/Account/components/Modal/ModalVerifyAccount';

// import Header from '../../components/Header/Header';
// import HeaderMobile from '../../components/HeaderMobile/HeaderMobile';
// import Footer from '../../components/Footer/Footer';
// import RunningText from '../../components/RunningText/RunningText';
// import MobileBottomBarV2 from '../../components/MobileBottomBarV2';
// import VideoModal from '../../components/VideoModal';
// import AddressChecker from '../../components/AddressChecker/AddressChecker';
// import FacebookChecker from '../../components/FacebookChecker/FacebookChecker';
// import { Helmet } from "react-helmet";

function RestoreScroll(props) {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return props.children;
}


const RestoringScroll = withRouter(RestoreScroll);

const MainPage = props => {
  // const { isFeedsPage } = props;
  // const [socket, setSocket] = useState();
  const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1224px)' });
  // const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' });
  // const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1223px)' });
  // const isPortrait = useMediaQuery({ query: '(orientation: portrait)' });
  // const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' });

  useEffect(() => {
    if (isDesktopOrLaptop) {
      props.setTypeDevice('dkstp');
    } else {
      props.setTypeDevice('mbl');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDesktopOrLaptop, props?.isLogin]);

  // Activate Chat Button
  // useEffect(() => {
  //   if (props.isLogin && props.chatToken) {
  //     if (!socket) {
  //       setSocket(io(process.env.REACT_APP_API_BACKEND_CHAT, {
  //         transports: ['websocket', 'polling'],
  //         query: {
  //           token: props.chatToken
  //         }
  //       }));
  //     }
  //   }
  //   // eslint-disable-next-line
  // }, [props.chatToken, props.isLogin]);

  // Disable Discuss
  // useEffect(() => {
  //   if (socket && props.inDiscussPage && props.listDiscussConversation) {
  //     socket.emit('joinRoom', { username: props.username, dataRooms: props.listDiscussConversation });
  //     socket.off("messageGroup");
  //     socket.on("messageGroup", message => {
  //       props.setNewChatGroup(message);
  //     });
  //   }
  //   // eslint-disable-next-line
  // }, [props.inDiscussPage, props.listDiscussConversation, socket]);

  // Activate Chat Button
  // useEffect(() => {
  //   if (props.toEmitNewMessage) {
  //     socket.emit("chatMessage", props.toEmitNewMessage);
  //     props.setNewEmitMessage(undefined);
  //   }
  //   // eslint-disable-next-line
  // }, [props.toEmitNewMessage]);

  // Disable Discuss
  // useEffect(() => {
  //   if (socket && props.openDiscussLP) {
  //     socket.emit('joinRoom', { username: props.username, dataRooms: [props.openDiscussLP] });

  //     socket.off("messageLp");
  //     socket.on("messageLp", message => {
  //       props.setNewChatGroup(message);
  //     });
  //   }
  //   // eslint-disable-next-line
  // }, [socket, props.openDiscussLP]);

  // Activate Chat Button
  // useEffect(() => {
  //   if (props.toEmitNewMessageLP) {
  //     socket.emit("chatMessageLP", props.toEmitNewMessageLP);
  //     props.setNewEmitMessageLP(undefined);
  //   }
  //   // eslint-disable-next-line
  // }, [props.toEmitNewMessageLP]);

  if (props.token) {
    let decodeToken = jwt.decode(props.token);
    // isEditor = decodeToken.isLpEditor;

    props.setIsEditorLP(decodeToken.isLpEditor);
    props.setIsCSLP(decodeToken.isLpCs);
    props.setUserRole(decodeToken.role);
  }

  useEffect(() => {
    document.querySelector('body')
      .classList
      .remove('body--no-scroll');
  }, []);

  return (
    <BrowserRouter>
      <RestoringScroll>
        <MetaPixelProvider>
          <ModalLoginProvider>
            <AccountProvider>
              <AccountVerifyProvider>
                <RefContext>
                  <Routing />
                </RefContext>
                <ModalVerifyAccount />
              </AccountVerifyProvider>
            </AccountProvider>
          </ModalLoginProvider>
        </MetaPixelProvider>
      </RestoringScroll>
    </BrowserRouter>
  );
};

const mapStateToProps = state => {
  return {
    isFeedsPage: state.isFeedsPage,
    screen: state.tpdvc,
    username: state.username,
    isLogin: state.isLogin,
    chatToken: state.chatToken,
    inDiscussPage: state.dscspg,
    listDiscussConversation: state.lsdscss,
    toEmitNewMessage: state.sndnwctgrp,
    toEmitNewMessageLP: state.sndnwctgrplp,
    openDiscussLP: state.opndscslp,
    token: state.token,
    userRole: state.userrole,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setTypeDevice: type =>
      dispatch({
        type: ActionType.SET_DEVICE,
        payload: { type: type }
      }),
    setNewChatGroup: message =>
      dispatch({
        type: ActionType.NEW_CHAT_GROUP,
        payload: { message: message }
      }),
    setNewChatLP: message =>
      dispatch({
        type: ActionType.NEW_CHAT_LP,
        payload: { message: message }
      }),
    setNewEmitMessage: (message) =>
      dispatch({
        type: ActionType.EMIT_NEW_CHAT_GROUP,
        payload: {
          message: message
        }
      }),
    setNewEmitMessageLP: (message) =>
      dispatch({
        type: ActionType.EMIT_NEW_CHAT_LP,
        payload: {
          message: message
        }
      }),
    setIsEditorLP: (isedtrlp) =>
      dispatch({
        type: ActionType.IS_EDITOR_LP,
        payload: {
          isedtrlp: isedtrlp
        }
      }),
    setIsCSLP: (iscslp) =>
      dispatch({
        type: ActionType.IS_CS_LP,
        payload: {
          iscslp: iscslp
        }
      }),
    setUserRole: (userrole) =>
      dispatch({
        type: ActionType.USER_ROLE,
        payload: {
          userrole: userrole
        }
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MainPage);