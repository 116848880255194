import Get from '../methods/Get';
import Post from '../methods/Post';
import Delete from '../methods/Delete';
import Patch from '../methods/Patch';

// ================== Cart ==================

// Add new product to Cart
const addCart =  (data, token) => Post('cart', data, token); 

// Get cart
const getCart = (token, params) => Get(`cart${params}`, { "Authorization": `Bearer ${token}` });

// Delete Cart by address
const deleteCartAddress = (id, token) => Delete(`cart/address/${id}`, { "Authorization": `Bearer ${token}` });

// Delete Cart by seller
const deleteCartSeller = (id, token) => Delete(`cart/seller/${id}`, { "Authorization": `Bearer ${token}` });

// Delete Item Cart
const deleteItemCart = (id, token, params) => Delete(`cart/${id}${params}`, { "Authorization": `Bearer ${token}` });

//  Update quantity per item
const updateQuantity = (id, data, token, params) => Patch(`cart/${id}${params}`, data, token);

export const cart = {
    addCart,
    getCart,
    deleteCartAddress,
    deleteCartSeller,
    deleteItemCart,
    updateQuantity
}