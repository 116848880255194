import axios from 'axios';
import Get from '../methods/Get';
import Post from '../methods/Post';

// eslint-disable-next-line
const CustomPost = (path, data, token) => {
  const body = {
    method: "post",
    url: `http://192.168.0.116:3000/${path}`,
    data: data,
  }

  if (token) body.headers = { "Authorization": `Bearer ${token}` }

  const promise = new Promise((resolve, reject) => {
    axios(body).then((result) => {
      resolve(result)
    }, (err) => {
      reject(err)
    })
  });

  return promise;
}

// eslint-disable-next-line
const CustomGet = (path, header) => {
  const promise = new Promise((resolve, reject) => {
    axios.get(`http://192.168.0.116:3000/${path}`, { headers: header })
      .then((result) => {
        resolve(result)
      }, (err) => {
        reject(err)
      });
  });

  return promise;
}

// ================== LANDING PAGE ==================
const listDomain = (token, params) => Get(`lp/domains${params}`, { "Authorization": `Bearer ${token}` });

const detailDomain = (token, id) => Get(`lp/domains/${id}`, { "Authorization": `Bearer ${token}` });

const getRegistrationLP = token => Get('lp/registration', { "Authorization": `Bearer ${token}` });

const checkDomain = (data, token) => Post('lp/check_domain', data, token);

const registDomain = (data, token) => Post('lp/registration', data, token);

const addDomain = (data, token) => Post('lp/add_domain', data, token);

const addProduct = (data, token) => Post('lp/add_product', data, token);

const getInvoiceLP = (id, token) => Get(`lp/invoices/${id}`, token ? { "Authorization": `Bearer ${token}` } : null);

const postInvoiceLP = (id, token, data) => Post(`lp/invoice-payment-proof/${id}`, data, token);

const getRegistrationBundle = () => Get('lp/registration-bundle', null);

const postRegisterBundle = data => Post('lp/registration-bundle', data);

const getRegistrationBundleTemp = () => Get('lp/registration-bundle-temp', null);

const postRegisterBundleTemp = data => Post('lp/registration-bundle-temp', data);

const getRegistrationTempLP = token => Get('lp/registration-temp', { "Authorization": `Bearer ${token}` });

const registTempLP = (token) => Post('lp/registration-temp', {}, token);

//  Generate PDF Order
const getPDFOrderLP = (id, token) => Get(`lp/orders/${id}/invoice-pdf`, { "Authorization": `Bearer ${token}` });

// Post Claim Photo
const postClaimPhoto = (token, data) => Post('lp/claim/upload', data, token);

// Post Form Complaint
const postClaim = (data, token) => Post('lp/claim', data, token);

// GET LP EXTEND
const getExtendLP = (token) => Get(`lp/invoice-extend`, { "Authorization": `Bearer ${token}` })

const postFreeExtendLPMembershipAlreadyPaid = (token, lpInvoiceId) => Post(`lp/free-extend-proof/${lpInvoiceId}`, {}, token)

const getAddonDomainExtendList = (token, params = '') => Get(`lp/domains/addons-extend?${params}`, { Authorization: `Bearer ${token}` })

const postPaymentAddonDomainExtend = (token, data) => Post('lp/domains/addons-extend/pay', data, token)

export const landingpage = {
  listDomain,
  getRegistrationLP,
  checkDomain,
  registDomain,
  detailDomain,
  addDomain,
  getInvoiceLP,
  postInvoiceLP,
  getRegistrationBundle,
  postRegisterBundle,
  getRegistrationBundleTemp,
  postRegisterBundleTemp,
  addProduct,
  getPDFOrderLP,
  postClaimPhoto,
  postClaim,
  getRegistrationTempLP,
  registTempLP,
  getExtendLP,
  postFreeExtendLPMembershipAlreadyPaid,
  getAddonDomainExtendList,
  postPaymentAddonDomainExtend,
}

