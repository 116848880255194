import Get from '../methods/Get';
import Post from '../methods/Post';

// Follow 
const followSeller = (id, token) => Post(`follow/${id}`, null, token);

// Follow Mitra
const followPartner = (id, token) => Post(`follow-partner/${id}`, null, token);

// Unfollow
const unfollow = (id, token) => Post(`unfollow/${id}`, null, token);

// Unfollow
const unfollowPartner = (id, token) => Post(`unfollow-partner/${id}`, null, token);

// Get following
const getFollowing = token => Get('list_following', { "Authorization": `Bearer ${token}` })

export const follow = {
    followSeller,
    unfollow,
    getFollowing,
    followPartner,
    unfollowPartner,
}