import Post from '../methods/Post';

// ================== Login ================== 

// eslint-disable-next-line
const key = process.env.REACT_APP_ACCESS_KEY;

const loginMember = data => Post(`login`, data);

export const login = {
    loginMember
}
