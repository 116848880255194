import {
    homepage,
    notification,
    product,
    voucher,
    complaint,
    orders,
    cart,
    cartBulk,
    checkout,
    invoice,
    login,
    logout,
    password,
    wallet,
    follow,
    bank,
    account,
    search,
    supplier,
    scrape,
    landingpage,
    debt,
    webinar,
    faq,
    templateFollowUp,
    uploadFile,
    releaseNotes,
    general,
    link,
    feeds,
    bulkOrders,
    paylaters,
    cartPartner,
    partnerOrders,
    registerSuppliers,
} from './modules';

const API = {
    login: login.loginMember,
    getHome: homepage.getHome,
    categories: homepage.categories,
    autoComplete: homepage.autoComplete,
    getNumberCS: homepage.getNumberCS,
    getRunningText: homepage.getRunningText,
    getDetailProduct: product.getDetailProduct,
    getProductsByCategory: product.getProductsByCategory,
    searchProduct: product.searchProduct,
    getCourierById: product.getCourierById,
    getProducts: product.getProducts,
    getProduct: product.getProduct,
    getProductRealStock: product.getProductRealStock,
    getProductSameStore: product.getProductSameStore,
    getProductSimilar: product.getProductSimilar,
    getVouchers: voucher.getVouchers,
    voucherStore: voucher.voucherStore,
    addVoucherCart: voucher.addVoucherCart,
    getVoucherBalance: voucher.getVoucherBalance,
    getVoucherInvoice: voucher.getVoucherInvoice,
    checkoutBuyVoucher: voucher.checkoutBuyVoucher,
    getVoucherMutation: voucher.getVoucherMutation,
    getHistoryVoucherInvoice: voucher.getHistoryVoucherInvoice,
    getListVoucherUnpaid: voucher.getListVoucherUnpaid,
    getVoucherExcel: voucher.getVoucherExcel,
    getNotifications: notification.getNotifications,
    newsNotification: notification.newsNotification,
    readNotification: notification.readNotification,
    postFileComplaint: complaint.postFileComplaint,
    postComplaint: complaint.postComplaint,
    postComplaint2: complaint.postComplaint2,
    postCompensite: complaint.postCompensite,
    getComplaint: complaint.getComplaint,
    getCompensation: complaint.getCompensation,
    listOrder: orders.listOrder,
    listOrderLandingPage: orders.listOrderLandingPage,
    detailOrder: orders.detailOrder,
    detailOrderLandingPage: orders.detailOrderLandingPage,
    trackingShipping: orders.trackingShipping,
    trackingShippingLp: orders.trackingShippingLp,
    postPriceSell: orders.postPriceSell,
    postFileUploadCheckout: orders.postFileUploadCheckout,
    exportExcel: orders.exportExcel,
    reportSeller: orders.reportSeller,
    addCart: cart.addCart,
    getCart: cart.getCart,
    deleteCartAddress: cart.deleteCartAddress,
    deleteCartSeller: cart.deleteCartSeller,
    deleteItemCart: cart.deleteItemCart,
    updateQuantity: cart.updateQuantity,
    checkout: checkout.checkoutNormal,
    checkoutInstant: checkout.checkoutInstant,
    shippingCost: checkout.shippingCost,
    postCheckout: checkout.postCheckout,
    postCheckoutInstant: checkout.postCheckoutInstant,
    postCheckoutBulk: checkout.postCheckoutBulk,
    postCheckoutPartner: checkout.postCheckoutPartner,
    invoiceImage: invoice.invoiceImage,
    postInvoice: invoice.postInvoice,
    getInvoiceRegister: invoice.getInvoiceRegister,
    getInvoice: invoice.getInvoice,
    getInvoiceExtend: invoice.getInvoiceExtend,
    getPDFOrder: invoice.getPDFOrder,
    changePassword: password.changePassword,
    checkPin: password.checkPin,
    addPin: password.addPin,
    changePin: password.changePin,
    resetPin: password.resetPin,
    checkPinConfirm: password.checkPinConfirm,
    resetPassword: password.resetPassword,
    forgotPassword: password.forgotPassword,
    getOtp: password.getOtp,
    postOtp: password.postOtp,
    getWallet: wallet.getWallet,
    getWalletMutation: wallet.getWalletMutation,
    getPassiveIncome: wallet.getPassiveIncome,
    getAffiliateList: wallet.getAffiliateList,
    getSubmentorList: wallet.getSubmentorList,
    getPassiveIncomeDetail: wallet.getPassiveIncomeDetail,
    postWithdrawalDana: wallet.postWithdrawalDana,
    postWithdrawalBonus: wallet.postWithdrawalBonus,
    getLastWithdrawal: wallet.getLastWithdrawal,
    follow: follow.followSeller,
    followPartner: follow.followPartner,
    unfollow: follow.unfollow,
    unfollowPartner: follow.unfollowPartner,
    getFollowing: follow.getFollowing,
    getMemberBank: bank.getMemberBank,
    getSenderBank: bank.getSenderBank,
    getListBank: bank.getListBank,
    getBank: bank.getBank,
    postMemberBank: bank.postMemberBank,
    getDetailMember: account.getDetailMember,
    patchProfileMember: account.patchProfileMember,
    postPhotoProfile: account.postPhotoProfile,
    getMemberAddress: account.getMemberAddress,
    patchMemberAddress: account.patchMemberAddress,
    postMemberAddress: account.postMemberAddress,
    getScrape: account.getScrape,
    createMember: account.createMember,
    postRegisterSeller: account.postRegisterSeller,
    checkExistUsername: account.checkExistUsername,
    checkExistEmail: account.checkExistEmail,
    checkExistPhone: account.checkExistPhone,
    checkExistUrlFacebook: account.checkExistUrlFacebook,
    postRegisterMember: account.postRegisterMember,
    getVideo: account.getVideo,
    getVideoSystem: account.getVideoSystem,
    getCsNumber: account.getCsNumber,
    getDashboard: account.getDashboard,
    dashboardOrders: account.dashboardOrders,
    getSearch: search.getSearch,
    searchAddress: search.searchAddress,
    getSupplier: supplier.getSupplier,
    listDomain: landingpage.listDomain,
    detailDomain: landingpage.detailDomain,
    addProduct: landingpage.addProduct,
    getRegistrationLP: landingpage.getRegistrationLP,
    checkDomain: landingpage.checkDomain,
    registDomain: landingpage.registDomain,
    addDomain: landingpage.addDomain,
    getInvoiceLP: landingpage.getInvoiceLP,
    postInvoiceLP: landingpage.postInvoiceLP,
    getRegistrationBundle: landingpage.getRegistrationBundle,
    postRegisterBundle: landingpage.postRegisterBundle,
    getRegistrationBundleTemp: landingpage.getRegistrationBundleTemp,
    postRegisterBundleTemp: landingpage.postRegisterBundleTemp,
    getRegistrationTempLP: landingpage.getRegistrationTempLP,
    registTempLP: landingpage.registTempLP,
    getPDFOrderLP: landingpage.getPDFOrderLP,
    postClaimPhoto: landingpage.postClaimPhoto,
    postClaim: landingpage.postClaim,
    scrapeCategories: scrape.scrapeCategories,
    logoutAll: logout.logoutAll,
    logoutAllSendEmail: logout.logoutAllSendEmail,
    dashboardDebt: debt.dashboardDebt,
    payDebtHistory: debt.payDebtHistory,
    getStatusDebt: debt.getStatusDebt,
    getListMustPayRTS: debt.getListMustPayRTS,
    payDebtRTS: debt.payDebtRTS,
    getInvoiceRTS: debt.getInvoiceRTS,
    postInvoiceRTS: debt.postInvoiceRTS,
    invoiceImageRTS: debt.invoiceImageRTS,
    debtImage: debt.debtImage,
    postDebt: debt.postDebt,
    getListingSupplier: supplier.getListingSupplier,
    getWebinar: webinar.getWebinar,
    getWebinarCategory: webinar.getWebinarCategory,
    debtPaymentDetail: debt.debtPaymentDetail,
    ...faq,
    ...templateFollowUp,
    ...uploadFile,
    ...releaseNotes,
    getProductRecomendationMP: product.getProductRecomendationMP,
    getProductRecomendationMPCategories: product.getProductRecomendationMPCategories,
    getProductRealStockSpreadSheet: product.getProductRealStockSpreadSheet,
    getExtendLP: landingpage.getExtendLP,
    postFreeExtendLPMembershipAlreadyPaid: landingpage.postFreeExtendLPMembershipAlreadyPaid,
    getAddonDomainExtendList: landingpage.getAddonDomainExtendList,
    postPaymentAddonDomainExtend: landingpage.postPaymentAddonDomainExtend,
    ...cartBulk,
    ...general,
    ...link,
    ...feeds,
    ...bulkOrders,
    ...paylaters,
    ...cartPartner,
    ...partnerOrders,
    ...registerSuppliers,
};

export default API;
