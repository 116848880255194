import Get from '../methods/Get';
import Post from '../methods/Post';

// Get member bank
const getMemberBank = (id, token) => Get(`bank?member_id=${id}`, { "Authorization": `Bearer ${token}` })

// Get sender bank
const getSenderBank = token => Get('bank-sender', { "Authorization": `Bearer ${token}` })

// Get List Bank Baleomol
const getListBank = (params, token) => Get(`bank-baleomol${params}`, { "Authorization": `Bearer ${token}` })

const getBank = (url) => Get(url);

// Post member bank
const postMemberBank = (data, token) => Post('bank', data, token);

export const bank = {
    getMemberBank,
    getSenderBank,
    getListBank,
    postMemberBank,
    getBank
}