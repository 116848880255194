import axios from "axios"
import { Backend } from '../Config'

const Patch = (path, data, token) => {
    const promise = new Promise((resolve, reject) => {
        axios({
            method: "patch",
            url: `${Backend}/${path}`,
            data: data,
            headers: { "Authorization": `Bearer ${token}` }
        }).then((result) => {
            resolve(result)
        }, (err) => {
            reject(err)
        })
    })
    return promise;
}

export default Patch;