import axios from 'axios'
import { Backend } from '../Config'

const Get = (path, header, cancelToken = null) => {
    const promise = new Promise((resolve, reject) => {
        const config = { headers:  header };
        if (cancelToken) config.cancelToken = cancelToken;

        axios.get(`${Backend}/${path}`, config)
            .then((result) => {
                resolve(result);
            }, (err) => {
                reject(err);
            })
            .catch((thrown) => {
                if (axios.isCancel(thrown)) {
                    console.warn({ requestCanceled: thrown.message });
                } else {
                    throw new Error(thrown);
                }
            });
    })
    return promise;
}

export default Get; 