import Get from '../methods/Get';
import Patch from '../methods/Patch';
import Post from '../methods/Post';

// ================== MEMBER ==================
// Get detail member 
const getDetailMember = (id, token) => Get(`members/${id}`, { "Authorization": `Bearer ${token}` });

// Patch profil member
const patchProfileMember = (id, data, token) => Patch(`members/${id}`, data, token);

// Post profile photo
// const postPhotoProfile = (id, token,formData) => PostFormData(`members/${id}/profile?save=true`, formData, token);
const postPhotoProfile = (id, token, data) => Post(`members/${id}/profile?save=true`, data, token);

// Get member address
const getMemberAddress = (params, token) => Get(`address${params}`, { "Authorization": `Bearer ${token}` });

//Patch member address
const patchMemberAddress = (id, token, data) => Patch(`address/edit/${id}`, data, token);

//Post member address
const postMemberAddress = (data, token) => Post(`address`, data, token);

// const searchAddress = (token, keyword) => Get(`address?keyword=${keyword}`, { "Authorization": `Bearer ${token}` });

// Get scrape
const getScrape = (params, token) => Get(`list_following${params}`, { "Authorization": `Bearer ${token}` })

// Post create member
const createMember = data => Post('members', data);

// Post register seller 
const postRegisterSeller = data => Post('register-seller', data);

// Check exist username
const checkExistUsername = (value) => Get(`check_exist?type=username&value=${value}`, null);

// Check exist email
const checkExistEmail = (value) => Get(`check_exist?type=email&value=${value}`, null);

// Check exist phone
const checkExistPhone = (value) => Get(`check_exist?type=phone&value=${value}`, null);

// Cek exist url facebook
const checkExistUrlFacebook = (value) => Get(`check_exist?type=url_facebook&value=${value}`, null);

// Post register member
const postRegisterMember = data => Post('register-member', data);

// Get video tutorial
const getVideo = ({ token, params }) => Get(`video_tutorial?${params}`, { "Authorization": `Bearer ${token}` });

// Get video tutorial
const getVideoSystem = ({ token, params }) => Get(`video-tutorial-system?${params}`, { "Authorization": `Bearer ${token}` });

// Get Nomor CS
const getCsNumber = (type, token) => Get(`cs_number?type=${type}`, { "Authorization": `Bearer ${token}` });

// Dashboard member
const getDashboard = (params, token) => Get(`dashboard-member${params}`, { "Authorization": `Bearer ${token}` });

// Dashboard member orders
const dashboardOrders = (params, token) => Get(`orders${params}`, { "Authorization": `Bearer ${token}` });

export const account = {
    getDetailMember,
    patchProfileMember,
    postPhotoProfile,
    getMemberAddress,
    patchMemberAddress,
    getScrape,
    createMember,
    postRegisterSeller,
    checkExistUsername,
    checkExistEmail,
    checkExistPhone,
    checkExistUrlFacebook,
    postRegisterMember,
    getVideo,
    getCsNumber,
    getDashboard,
    dashboardOrders,
    postMemberAddress,
    getVideoSystem,
}

