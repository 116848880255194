import Get from '../methods/Get';
import Post from '../methods/Post';

// Get Dashboard Debt 
const dashboardDebt = (token, params) =>  Get(`debt_dashboard${params}`, { "Authorization": `Bearer ${token}` });

// Get Dashboard Debt 
const payDebtHistory = (token, params) =>  Get(`debt_payment_history${params}`, { "Authorization": `Bearer ${token}` });

// Get Debt Payment Detail 
const debtPaymentDetail = (id, token) =>  Get(`debt_payment_history_detail/${id}`, { "Authorization": `Bearer ${token}` });

// Get list order must pay RTS
const getListMustPayRTS = (token, params) =>  Get(`lp/list_must_pay_rts${params}`, { "Authorization": `Bearer ${token}` });

// Get Status Debt 
const getStatusDebt = (token) =>  Get(`debt`, { "Authorization": `Bearer ${token}` });

// Post Debt RTS
const payDebtRTS = (data, token) =>  Post(`pay_debt`, data, token);

// Get invoice RTS
const getInvoiceRTS = (id, token) => Get(`invoices-rts/${id}`, { "Authorization": `Bearer ${token}` });

// Post Invoice RTS
const postInvoiceRTS = (id, token, data) =>  Post(`upload_proof/${id}?type=rts`, data, token);

//Post Invoice Image RTS
const invoiceImageRTS = (id, token, data) =>  Post(`upload_proof_image/${id}?type=rts`, data, token);

// Upload debt image
const debtImage = (token, data) =>  Post(`debt_upload`, data, token);

// Post Debt
const postDebt = (data, token) => Post('debt', data, token);


export const debt = {
  dashboardDebt,
  payDebtHistory,
  getListMustPayRTS,
  getStatusDebt,
  payDebtRTS,
  getInvoiceRTS,
  postInvoiceRTS,
  invoiceImageRTS,
  debtImage,
  postDebt,
  debtPaymentDetail,
}
