import Get from '../methods/Get';

// ================== PRODUCT ==================
// Get detail product
const getDetailProduct = (id, token) => Get(`product/${id}/detail`, { "Authorization": `Bearer ${token}` });

// Get products by category
const getProductsByCategory = (params, token) => Get(`search${params}`, { "Authorization": `Bearer ${token}` });

// Products by search
const searchProduct = (params, token) => Get(`search${params}`, { "Authorization": `Bearer ${token}` });

// Get courier shipping by id
const getCourierById = id => Get(`couriers/${id}`, null);

// Get products 
const getProducts = (params, token) => Get(`products${params}`, { "Authorization": `Bearer ${token}` });

const getProductRealStock = (url, token) => Get(url, { "Authorization": `Bearer ${token}` });

// GET products
const getProduct = (id, token) => Get(`products/${id}`, (token == null) ? '' : { "Authorization": `Bearer ${token}` });

// GET ProductSameStore
const getProductSameStore = (id, token) => Get(`products-same-store/${id}`, (token == null) ? '' : { "Authorization": `Bearer ${token}` });

// GET ProductSimilar
const getProductSimilar = (id, token) => Get(`products-similar/${id}`, (token == null) ? '' : { "Authorization": `Bearer ${token}` });

// GET Product Recomendation MP 
const getProductRecomendationMP = (token, params) => Get(`products-reccomendation-from-marketplace${params}`, (token == null) ? '' : { "Authorization": `Bearer ${token}` })

const getProductRecomendationMPCategories = (token) => Get(`products-reccomendation-from-marketplace/category`, (token == null) ? '' : { "Authorization": `Bearer ${token}` })

const getProductRealStockSpreadSheet = ({token, storeId}) => Get(`products-real-stock-spreadsheet/${storeId}`, { "Authorization": `Bearer ${token}` });


export const product = {
  getDetailProduct,
  getProductsByCategory,
  searchProduct,
  getCourierById,
  getProducts,
  getProductRealStock,
  getProduct,
  getProductSameStore,
  getProductSimilar,
  getProductRecomendationMP,
  getProductRecomendationMPCategories,
  getProductRealStockSpreadSheet,
}