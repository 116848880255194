import Get from '../methods/Get';
import Post from '../methods/Post';


// Get Checkout
const checkoutNormal = token => Get('checkout', { "Authorization": `Bearer ${token}` });

// Get Checkout Instant
const checkoutInstant = token => Get('checkoutInstant', { "Authorization": `Bearer ${token}` });

// Get Shipping Cost
const shippingCost = (origin, destination, metric, courier, token) => Get(`get_ongkir?origin=${origin}&destination=${destination}&metric=${metric}&courier=${courier}`, { "Authorization": `Bearer ${token}` });

// Post Checkout
const postCheckout = (data, token) => Post('checkout', data, token);

// Post Checkout Instant
const postCheckoutInstant = (data, token) => Post('checkoutInstant', data, token);

// Post Checkout Massal
const postCheckoutBulk = (data, token) => Post('checkoutBulk', data, token);

// Post Checkout Massal
const postCheckoutPartner = (data, token) => Post('checkout-partner', data, token);

export const checkout = {
    checkoutNormal,
    checkoutInstant,
    shippingCost,
    postCheckout,
    postCheckoutInstant,
    postCheckoutPartner,
    postCheckoutBulk,
}